import { getLanguageFromLocale } from "../utils/language";
import { ALLEN_DIGITAL_HOST } from "./constants";
import { courseNavigationDataConfig } from "../../config-constants";

const subjects: any = {
  en: {
    12: ["Maths", "Physics", "Chemistry", "Biology", "English"],
    11: ["Maths", "Physics", "Chemistry", "Biology", "English"],
    10: ["Maths", "Physics", "Chemistry", "Biology", "English", "Reasoning"],
    9: ["Maths", "Physics", "Chemistry", "Biology", "English"],
    8: ["Maths", "Physics", "Chemistry", "Biology", "English"],
    7: ["Maths", "Physics", "Chemistry", "Biology", "English"],
    6: ["Maths", "Physics", "Chemistry", "Biology", "English"],
  },
}

export const courseNavigationData = courseNavigationDataConfig;

export const reverseCourseNavigationData: { [key: string]: { category: string }[] } = {
  "11": [
    { "category": "IIT-JEE" },
    { "category": "NEET" },
    { "category": "UP Board" },
    { "category": "Bihar Board" },
    { "category": "CBSE Board" },
    { "category": "MP Board" },
    { "category": "Jharkhand Board" },
    { "category": "Haryana Board" },
    { "category": "Himachal Board" },
    { "category": "Chhattisgarh Board" },
    { "category": "Uttarakhand Board" },
    { "category": "Rajasthan Board" },
  ],
  "12": [
    { "category": "IIT-JEE" },
    { "category": "NEET" },
    { "category": "UP Board" },
    { "category": "Bihar Board" },
    { "category": "CBSE Board" },
    { "category": "MP Board" },
    { "category": "Jharkhand Board" },
    { "category": "Haryana Board" },
    { "category": "Himachal Board" },
    { "category": "Chhattisgarh Board" },
    { "category": "Uttarakhand Board" },
    { "category": "Rajasthan Board" },
  ],
  "13": [
    { "category": "IIT-JEE" },
    { "category": "NEET" },
  ],
  "9": [
    { "category": "UP Board" },
    { "category": "Bihar Board" },
    { "category": "CBSE Board" },
    { "category": "MP Board" },
    { "category": "Jharkhand Board" },
    { "category": "Haryana Board" },
    { "category": "Himachal Board" },
    { "category": "Chhattisgarh Board" },
    { "category": "Uttarakhand Board" },
    { "category": "Rajasthan Board" },
  ],
  "10": [
    { "category": "UP Board" },
    { "category": "Bihar Board" },
    { "category": "CBSE Board" },
    { "category": "MP Board" },
    { "category": "Jharkhand Board" },
    { "category": "Haryana Board" },
    { "category": "Himachal Board" },
    { "category": "Chhattisgarh Board" },
    { "category": "Uttarakhand Board" },
    { "category": "Rajasthan Board" },
  ],
}

export const examNavigationData = {
  name: "Exam",
  content: [{
    name: "IIT-JEE", url: "/iit-jee",
  }, {
    name: "NEET", url: "/neet",
  }, {
    name: "CBSE", url: "/cbse",
  }, {
    name: "UP Board", url: "/up-board",
  }, {
    name: "Bihar Board", url: "/bihar-board",
  }],
};

export const getNcertNavigationData = () => {
  const menuItems = [];
  for (const locale in subjects) {
    const sectionItem: any = { name: `Ncert Solutions ${getLanguageFromLocale(locale)} Medium`, content: [] };
    for (const studentClass in subjects[locale]) {
      const item = { name: `Class ${studentClass}`, content: [] };
      const subjectNames = subjects[locale][studentClass];
      item.content = subjectNames.map((subjectName: string) => ({
        name: subjectName,
        url: `/class-${studentClass}/${subjectName.replace(/\(.*\)/, "").toLowerCase().trim()}`,
        next: true,
      }));
      sectionItem.content.push(item);
    }
    menuItems.push(sectionItem);
  }
  return menuItems;
}